const RegionSelectReducer = (state, action) => {
    // Region reducer is used to reduce complexity when updating mover preferences
    // Case will be the individual regions
    switch (action.type) {
        case "farNorth":
            return { ...state, farNorth: !state.farNorth };
        case "kaipara":
            return { ...state, kaipara: !state.kaipara };
        case "whangarei":
            return { ...state, whangarei: !state.whangarei };
        case "aucklandCity":
            return { ...state, aucklandCity: !state.aucklandCity };
        case "franklin":
            return { ...state, franklin: !state.franklin };
        case "haurakiGulfIslands":
            return { ...state, haurakiGulfIslands: !state.haurakiGulfIslands };
        case "manukauCity":
            return { ...state, manukauCity: !state.manukauCity };
        case "northShoreCity":
            return { ...state, northShoreCity: !state.northShoreCity };
        case "papakura":
            return { ...state, papakura: !state.papakura };
        case "rodney":
            return { ...state, rodney: !state.rodney };
        case "waihekeIsland":
            return { ...state, waihekeIsland: !state.waihekeIsland };
        case "waitakereCity":
            return { ...state, waitakereCity: !state.waitakereCity };
        case "hamilton":
            return { ...state, hamilton: !state.hamilton };
        case "hauraki ":
            return { ...state, hauraki: !state.hauraki };
        case "matamataPiako":
            return { ...state, matamataPiako: !state.matamataPiako };
        case "otorohanga":
            return { ...state, otorohanga: !state.otorohanga };
        case "southWaikato":
            return { ...state, southWaikato: !state.southWaikato };
        case "taupo":
            return { ...state, taupo: !state.taupo };
        case "thamesCoromandel":
            return { ...state, thamesCoromandel: !state.thamesCoromandel };
        case "waikato":
            return { ...state, waikato: !state.waikato };
        case "waipa":
            return { ...state, waipa: !state.waipa };
        case "waitomo":
            return { ...state, waitomo: !state.waitomo };
        case "kawerau":
            return { ...state, kawerau: !state.kawerau };
        case "opotiki":
            return { ...state, opotiki: !state.opotiki };
        case "rotorua":
            return { ...state, rotorua: !state.rotorua };
        case "tauranga":
            return { ...state, tauranga: !state.tauranga };
        case "westernBayOfPlenty":
            return { ...state, westernBayOfPlenty: !state.westernBayOfPlenty };
        case "whakatane":
            return { ...state, whakatane: !state.whakatane };
        case "gisborne":
            return { ...state, gisborne: !state.gisborne };
        case "centralHawkesBay":
            return { ...state, centralHawkesBay: !state.centralHawkesBay };
        case "hastings":
            return { ...state, hastings: !state.hastings };
        case "napier":
            return { ...state, napier: !state.napier };
        case "wairoa":
            return { ...state, wairoa: !state.wairoa };
        case "newPlymouth":
            return { ...state, newPlymouth: !state.newPlymouth };
        case "southTaranaki":
            return { ...state, southTaranaki: !state.southTaranaki };
        case "stratford":
            return { ...state, stratford: !state.stratford };
        case "horowhenua":
            return { ...state, horowhenua: !state.horowhenua };
        case "manawatu":
            return { ...state, manawatu: !state.manawatu };
        case "palmerstonNorth":
            return { ...state, palmerstonNorth: !state.palmerstonNorth };
        case "rangitikei":
            return { ...state, rangitikei: !state.rangitikei };
        case "ruapehu":
            return { ...state, ruapehu: !state.ruapehu };
        case "tararua":
            return { ...state, tararua: !state.tararua };
        case "whanganui":
            return { ...state, whanganui: !state.whanganui };
        case "carterton":
            return { ...state, carterton: !state.carterton };
        case "kapiti":
            return { ...state, kapiti: !state.kapiti };
        case "lowerHutt":
            return { ...state, lowerHutt: !state.lowerHutt };
        case "masterton":
            return { ...state, masterton: !state.masterton };
        case "porirua":
            return { ...state, porirua: !state.porirua };
        case "southWairarapa":
            return { ...state, southWairarapa: !state.southWairarapa };
        case "upperHutt":
            return { ...state, upperHutt: !state.upperHutt };
        case "wellington":
            return { ...state, wellington: !state.wellington };
        case "nelson":
            return { ...state, nelson: !state.nelson };
        case "tasman":
            return { ...state, tasman: !state.tasman };
        case "blenheim":
            return { ...state, blenheim: !state.blenheim };
        case "kaikoura":
            return { ...state, kaikoura: !state.kaikoura };
        case "marlborough":
            return { ...state, marlborough: !state.marlborough };
        case "buller":
            return { ...state, buller: !state.buller };
        case "grey":
            return { ...state, grey: !state.grey };
        case "westland":
            return { ...state, westland: !state.westland };
        case "ashburton":
            return { ...state, ashburton: !state.ashburton };
        case "banksPeninsula":
            return { ...state, banksPeninsula: !state.banksPeninsula };
        case "christchurchCity":
            return { ...state, christchurchCity: !state.christchurchCity };
        case "hurunui":
            return { ...state, hurunui: !state.hurunui };
        case "mackenzie":
            return { ...state, mackenzie: !state.mackenzie };
        case "selwyn":
            return { ...state, selwyn: !state.selwyn };
        case "timaru":
            return { ...state, timaru: !state.timaru };
        case "waimakariri":
            return { ...state, waimakariri: !state.waimakariri };
        case "waimate":
            return { ...state, waimate: !state.waimate };
        case "centralOtago":
            return { ...state, centralOtago: !state.centralOtago };
        case "clutha":
            return { ...state, clutha: !state.clutha };
        case "dunedin":
            return { ...state, dunedin: !state.dunedin };
        case "queenstownLakes":
            return { ...state, queenstownLakes: !state.queenstownLakes };
        case "southOtago":
            return { ...state, southOtago: !state.southOtago };
        case "waitaki":
            return { ...state, waitaki: !state.waitaki };
        case "wanaka":
            return { ...state, wanaka: !state.wanaka };
        case "catlins":
            return { ...state, catlins: !state.catlins };
        case "gore":
            return { ...state, gore: !state.gore };
        case "invercargill":
            return { ...state, invercargill: !state.invercargill };
        case "southland":
            return { ...state, southland: !state.southland };
        default:
            throw new Error("Reducer case not found");
    }
};

export default RegionSelectReducer;
