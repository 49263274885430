import { useState, React, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
//import randomColor from 'randomcolor';
import {
    InputLabel,
    FormControl,
    Modal,
    Card,
    CardContent,
    Typography,
} from "@material-ui/core";
//import { DatePicker } from "@material-ui/pickers";
import { firestore } from "../../firebase";
//import { getFirestore } from 'firebase/firestore'
import PlacesAutocomplete from "react-places-autocomplete";

//import DateFnsUtils from '@date-io/date-fns';
//import {
//  MuiPickersUtilsProvider,
//  KeyboardTimePicker,
//  KeyboardDatePicker,
//} from '@material-ui/pickers';

import { Grid, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            //margin: theme.spacing(1),
            width: "100%",
            alignItems: "center",
            padding: theme.spacing(1),
            backgroundColor: "white",
        },
        button: {
            margin: theme.spacing(4),
        },
    },
    formControl: {
        //margin: theme.spacing(1),
        width: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        margin: theme.spacing(3),
    },
}));

const disableHeader = () => {
    try {
        let element = document.getElementsByTagName("header");
        element[0].style.display = "none";
    } catch (error) {
        console.log(error);
    }
};

const Copyright = (props) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://www.movable.com/">
                Movable New Zealand
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
};

const QuoteForm = (props) => {

    const classes = useStyles();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [moveDate, setMoveDate] = useState("");
    const [houseSize, setHouseSize] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pickupAddress, setPickupAddress] = useState("");
    const [dropOffAddress, setDropOffAddress] = useState("");
    const [dropOffArea, setDropOffRegion] = useState("");
    const [pickupArea, setPickupRegion] = useState("");

    const firstNameInputChangeHandler = (event) => {
        setFirstName(event.target.value);
    };
    const lastNameInputChangeHandler = (event) => {
        setLastName(event.target.value);
    };
    const emailInputChangeHandler = (event) => {
        setEmail(event.target.value);
    };
    const houseSizeChangeHandler = (event) => {
        setHouseSize(event.target.value);
    };
    const dateInputChangeHandler = (event) => {
        setMoveDate(event.target.value);
    };
    const phoneInputChangeHandler = (event) => {
        setPhoneNumber(event.target.value);
    };
    const pickupAreaChangeHandler = (event) => {
        setPickupRegion(event.target.value);
    };
    const dropOffAreaChangeHandler = (event) => {
        setDropOffRegion(event.target.value);
    };

    // Handle the pickup autocomplete
    const handlePickupSelect = async (value) => {
        setPickupAddress(value);
    };

    // Handle the dropOff autocomplete
    const handleDropOffSelect = async (value) => {
        setDropOffAddress(value);
        // console.log(value);
    };

    const addQuoteToFirebase = (quoteData) => {
        // Add a new document in collection "quotes"
        firestore
            .collection("quotes")
            .add(quoteData)
            .then(() => {
                console.log("Quote logged");
            })
            .catch((error) => {
                console.error("Error logging quote: ", error);
            });
        // console.log("addQuoteToFirebase has been run");
    };

    const formSubmissionHandler = (event) => {
        event.preventDefault();


        // console.log(firstName);
        // console.log(lastName);
        // console.log(email);
        // console.log(houseSize);
        // console.log(moveDate);
        // console.log(phoneNumber);
        // console.log(pickupAddress);
        // console.log(dropOffAddress);
        // console.log(pickupArea);
        // console.log(dropOffArea);

        addQuoteToFirebase({
            firstName: firstName,
            lastName: lastName,
            email: email,
            houseSize: houseSize,
            moveDate: moveDate,
            phoneNumber: phoneNumber,
            pickupAddress: pickupAddress,
            dropOffAddress: dropOffAddress,
            quoteRequestTimestamp: Date(),
            pickupArea: pickupArea,
            dropOffArea: dropOffArea,

        });

        setFormSubmitted(true);

    };

    //disableHeader so that we dont have it when we render the form
    useEffect(() => {
        disableHeader();
    });

    return (
        <>
            <Container
                component="main"
                maxWidth="xs"
                style={{ backgroundColor: "white", paddingBottom: "40px" }}
            >
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    style={{ backgroundColor: "white", paddingBottom: "40px" }}
                >
                    <Box
                        component="form"
                        onSubmit={formSubmissionHandler}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First name"
                                    type="text"
                                    id="first-name-input"
                                    onChange={firstNameInputChangeHandler}
                                    variant="outlined"
                                    fullWidth
                                    value={firstName}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last name"
                                    type="text"
                                    id="last-name-input"
                                    onChange={lastNameInputChangeHandler}
                                    variant="outlined"
                                    value={lastName}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    id="email-input"
                                    onChange={emailInputChangeHandler}
                                    variant="outlined"
                                    value={email}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Phone number"
                                    type="text"
                                    id="phone-number-input"
                                    onChange={phoneInputChangeHandler}
                                    variant="outlined"
                                    value={phoneNumber}
                                    //required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PlacesAutocomplete
                                    value={pickupAddress}
                                    onChange={setPickupAddress}
                                    onSelect={handlePickupSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                label="Pickup address"
                                                type="text"
                                                required
                                                id="pickup-address-input"
                                                fullWidth
                                                {...getInputProps({
                                                    placeholder:
                                                        "Type pickup address",
                                                })}
                                            ></TextField>
                                            <div>
                                                {loading ? (
                                                    <div>...Loading</div>
                                                ) : null}

                                                {suggestions.map(
                                                    (suggestion) => {
                                                        //console.log(suggestion);
                                                        const style = {
                                                            backgroundColor:
                                                                suggestion.active
                                                                    ? "#5bd1d7"
                                                                    : "#fff",
                                                        };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        style,
                                                                    }
                                                                )}
                                                            >
                                                                {
                                                                    suggestion.description
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    required
                                    xs={12}
                                >
                                    <InputLabel>Pickup area</InputLabel>
                                    <Select
                                        labelId="pickup-area-label"
                                        id="pickup-area-selector"
                                        //value={age}
                                        //onChange={handleChange}
                                        label="Pickup area"
                                        required
                                        value={pickupArea}
                                        onChange={pickupAreaChangeHandler}
                                        fullWidth
                                    >
                                        <MenuItem value={"ashburton"}> Ashburton </MenuItem>
                                        <MenuItem value={"aucklandCity"}> Auckland City </MenuItem>
                                        <MenuItem value={"banksPeninsula"}> Banks Peninsula </MenuItem>
                                        <MenuItem value={"blenheim"}> Blenheim </MenuItem>
                                        <MenuItem value={"buller"}> Buller </MenuItem>
                                        <MenuItem value={"carterton"}> Carterton </MenuItem>
                                        <MenuItem value={"catlins"}> Catlins </MenuItem>
                                        <MenuItem value={"centralHawkesBay"}> Central Hawke's Bay </MenuItem>
                                        <MenuItem value={"centralOtago"}> Central Otago </MenuItem>
                                        <MenuItem value={"christchurchCity"}> Christchurch City </MenuItem>
                                        <MenuItem value={"clutha"}> Clutha </MenuItem>
                                        <MenuItem value={"dunedin"}> Dunedin </MenuItem>
                                        <MenuItem value={"farNorth"}> Far North </MenuItem>
                                        <MenuItem value={"franklin"}> Franklin </MenuItem>
                                        <MenuItem value={"gisborne"}> Gisborne </MenuItem>
                                        <MenuItem value={"gore"}> Gore </MenuItem>
                                        <MenuItem value={"grey"}> Grey </MenuItem>
                                        <MenuItem value={"hamilton"}> Hamilton </MenuItem>
                                        <MenuItem value={"hastings"}> Hastings </MenuItem>
                                        <MenuItem value={"hauraki"}> Hauraki </MenuItem>
                                        <MenuItem value={"haurakiGulfIslands"}> Hauraki Gulf Islands </MenuItem>
                                        <MenuItem value={"horowhenua"}> Horowhenua </MenuItem>
                                        <MenuItem value={"hurunui"}> Hurunui </MenuItem>
                                        <MenuItem value={"invercargill"}> Invercargill </MenuItem>
                                        <MenuItem value={"kaikoura"}> Kaikoura </MenuItem>
                                        <MenuItem value={"kaipara"}> Kaipara </MenuItem>
                                        <MenuItem value={"kapiti"}> Kapiti Coast </MenuItem>
                                        <MenuItem value={"kawerau"}> Kawerau </MenuItem>
                                        <MenuItem value={"lowerHutt"}> Lower Hutt </MenuItem>
                                        <MenuItem value={"mackenzie"}> Mackenzie </MenuItem>
                                        <MenuItem value={"manawatu"}> Manawatu </MenuItem>
                                        <MenuItem value={"manukauCity"}> Manukau City </MenuItem>
                                        <MenuItem value={"marlborough"}> Marlborough </MenuItem>
                                        <MenuItem value={"masterton"}> Masterton </MenuItem>
                                        <MenuItem value={"matamataPiako"}> Matamata-Piako </MenuItem>
                                        <MenuItem value={"napier"}> Napier </MenuItem>
                                        <MenuItem value={"nelson"}> Nelson </MenuItem>
                                        <MenuItem value={"newPlymouth"}> New Plymouth </MenuItem>
                                        <MenuItem value={"northShoreCity"}> North Shore City </MenuItem>
                                        <MenuItem value={"opotiki"}> Opotiki </MenuItem>
                                        <MenuItem value={"otorohanga"}> Otorohanga </MenuItem>
                                        <MenuItem value={"palmerstonNorth"}> Palmerston North </MenuItem>
                                        <MenuItem value={"papakura"}> Papakura </MenuItem>
                                        <MenuItem value={"porirua"}> Porirua </MenuItem>
                                        <MenuItem value={"queenstownLakes"}> Queenstown-lakes </MenuItem>
                                        <MenuItem value={"rangitikei"}> Rangitikei </MenuItem>
                                        <MenuItem value={"rodney"}> Rodney </MenuItem>
                                        <MenuItem value={"rotorua"}> Rotorua </MenuItem>
                                        <MenuItem value={"ruapehu"}> Ruapehu </MenuItem>
                                        <MenuItem value={"selwyn"}> Selwyn </MenuItem>
                                        <MenuItem value={"southland"}> Southland </MenuItem>
                                        <MenuItem value={"southOtago"}> South Otago </MenuItem>
                                        <MenuItem value={"southTaranaki"}> South Taranaki </MenuItem>
                                        <MenuItem value={"southWaikato"}> South Waikato </MenuItem>
                                        <MenuItem value={"southWairarapa"}> South Wairarapa </MenuItem>
                                        <MenuItem value={"stratford"}> Stratford </MenuItem>
                                        <MenuItem value={"tararua"}> Tararua </MenuItem>
                                        <MenuItem value={"tasman"}> Tasman </MenuItem>
                                        <MenuItem value={"taupo"}> Taupo </MenuItem>
                                        <MenuItem value={"tauranga"}> Tauranga </MenuItem>
                                        <MenuItem value={"thamesCoromandel"}> Thames-Coromandel </MenuItem>
                                        <MenuItem value={"timaru"}> Timaru </MenuItem>
                                        <MenuItem value={"upperHutt"}> Upper Hutt </MenuItem>
                                        <MenuItem value={"waihekeIsland"}> Waiheke Island </MenuItem>
                                        <MenuItem value={"waikato"}> Waikato </MenuItem>
                                        <MenuItem value={"waimakariri"}> Waimakariri </MenuItem>
                                        <MenuItem value={"waimate"}> Waimate </MenuItem>
                                        <MenuItem value={"waipa"}> Waipa </MenuItem>
                                        <MenuItem value={"wairoa"}> Wairoa </MenuItem>
                                        <MenuItem value={"waitakereCity"}> Waitakere City </MenuItem>
                                        <MenuItem value={"waitaki"}> Waitaki </MenuItem>
                                        <MenuItem value={"waitomo"}> Waitomo </MenuItem>
                                        <MenuItem value={"wanaka"}> Wanaka </MenuItem>
                                        <MenuItem value={"wellington"}> Wellington </MenuItem>
                                        <MenuItem value={"westernBayOfPlenty"}> Western Bay Of Plenty </MenuItem>
                                        <MenuItem value={"westland"}> Westland </MenuItem>
                                        <MenuItem value={"whakatane"}> Whakatane </MenuItem>
                                        <MenuItem value={"whanganui"}> Whanganui </MenuItem>
                                        <MenuItem value={"whangarei"}> Whangarei </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <PlacesAutocomplete
                                    value={dropOffAddress}
                                    onChange={setDropOffAddress}
                                    onSelect={handleDropOffSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <div>
                                            <TextField
                                                variant="outlined"
                                                label="Drop off address"
                                                type="text"
                                                required
                                                id="drop-off-address-input"
                                                fullWidth
                                                {...getInputProps({
                                                    placeholder:
                                                        "Type drop off address",
                                                })}
                                            ></TextField>
                                            <div>
                                                {loading ? (
                                                    <div>...Loading</div>
                                                ) : null}

                                                {suggestions.map(
                                                    (suggestion) => {
                                                        //console.log(suggestion);
                                                        const style = {
                                                            backgroundColor:
                                                                suggestion.active
                                                                    ? "#5bd1d7"
                                                                    : "#fff",
                                                        };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        style,
                                                                    }
                                                                )}
                                                            >
                                                                {
                                                                    suggestion.description
                                                                }
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    required
                                    xs={12}
                                >
                                    <InputLabel>Drop off area</InputLabel>
                                    <Select
                                        labelId="drop-off-area-label"
                                        id="drop-off-area-selector"
                                        //value={age}
                                        //onChange={handleChange}
                                        label="Drop off area"
                                        required
                                        value={dropOffArea}
                                        onChange={dropOffAreaChangeHandler}
                                        fullWidth
                                    >
                                        <MenuItem value={"ashburton"}> Ashburton </MenuItem>
                                        <MenuItem value={"aucklandCity"}> Auckland City </MenuItem>
                                        <MenuItem value={"banksPeninsula"}> Banks Peninsula </MenuItem>
                                        <MenuItem value={"blenheim"}> Blenheim </MenuItem>
                                        <MenuItem value={"buller"}> Buller </MenuItem>
                                        <MenuItem value={"carterton"}> Carterton </MenuItem>
                                        <MenuItem value={"catlins"}> Catlins </MenuItem>
                                        <MenuItem value={"centralHawkesBay"}> Central Hawke's Bay </MenuItem>
                                        <MenuItem value={"centralOtago"}> Central Otago </MenuItem>
                                        <MenuItem value={"christchurchCity"}> Christchurch City </MenuItem>
                                        <MenuItem value={"clutha"}> Clutha </MenuItem>
                                        <MenuItem value={"dunedin"}> Dunedin </MenuItem>
                                        <MenuItem value={"farNorth"}> Far North </MenuItem>
                                        <MenuItem value={"franklin"}> Franklin </MenuItem>
                                        <MenuItem value={"gisborne"}> Gisborne </MenuItem>
                                        <MenuItem value={"gore"}> Gore </MenuItem>
                                        <MenuItem value={"grey"}> Grey </MenuItem>
                                        <MenuItem value={"hamilton"}> Hamilton </MenuItem>
                                        <MenuItem value={"hastings"}> Hastings </MenuItem>
                                        <MenuItem value={"hauraki"}> Hauraki </MenuItem>
                                        <MenuItem value={"haurakiGulfIslands"}> Hauraki Gulf Islands </MenuItem>
                                        <MenuItem value={"horowhenua"}> Horowhenua </MenuItem>
                                        <MenuItem value={"hurunui"}> Hurunui </MenuItem>
                                        <MenuItem value={"invercargill"}> Invercargill </MenuItem>
                                        <MenuItem value={"kaikoura"}> Kaikoura </MenuItem>
                                        <MenuItem value={"kaipara"}> Kaipara </MenuItem>
                                        <MenuItem value={"kapiti"}> Kapiti Coast </MenuItem>
                                        <MenuItem value={"kawerau"}> Kawerau </MenuItem>
                                        <MenuItem value={"lowerHutt"}> Lower Hutt </MenuItem>
                                        <MenuItem value={"mackenzie"}> Mackenzie </MenuItem>
                                        <MenuItem value={"manawatu"}> Manawatu </MenuItem>
                                        <MenuItem value={"manukauCity"}> Manukau City </MenuItem>
                                        <MenuItem value={"marlborough"}> Marlborough </MenuItem>
                                        <MenuItem value={"masterton"}> Masterton </MenuItem>
                                        <MenuItem value={"matamataPiako"}> Matamata-Piako </MenuItem>
                                        <MenuItem value={"napier"}> Napier </MenuItem>
                                        <MenuItem value={"nelson"}> Nelson </MenuItem>
                                        <MenuItem value={"newPlymouth"}> New Plymouth </MenuItem>
                                        <MenuItem value={"northShoreCity"}> North Shore City </MenuItem>
                                        <MenuItem value={"opotiki"}> Opotiki </MenuItem>
                                        <MenuItem value={"otorohanga"}> Otorohanga </MenuItem>
                                        <MenuItem value={"palmerstonNorth"}> Palmerston North </MenuItem>
                                        <MenuItem value={"papakura"}> Papakura </MenuItem>
                                        <MenuItem value={"porirua"}> Porirua </MenuItem>
                                        <MenuItem value={"queenstownLakes"}> Queenstown-lakes </MenuItem>
                                        <MenuItem value={"rangitikei"}> Rangitikei </MenuItem>
                                        <MenuItem value={"rodney"}> Rodney </MenuItem>
                                        <MenuItem value={"rotorua"}> Rotorua </MenuItem>
                                        <MenuItem value={"ruapehu"}> Ruapehu </MenuItem>
                                        <MenuItem value={"selwyn"}> Selwyn </MenuItem>
                                        <MenuItem value={"southland"}> Southland </MenuItem>
                                        <MenuItem value={"southOtago"}> South Otago </MenuItem>
                                        <MenuItem value={"southTaranaki"}> South Taranaki </MenuItem>
                                        <MenuItem value={"southWaikato"}> South Waikato </MenuItem>
                                        <MenuItem value={"southWairarapa"}> South Wairarapa </MenuItem>
                                        <MenuItem value={"stratford"}> Stratford </MenuItem>
                                        <MenuItem value={"tararua"}> Tararua </MenuItem>
                                        <MenuItem value={"tasman"}> Tasman </MenuItem>
                                        <MenuItem value={"taupo"}> Taupo </MenuItem>
                                        <MenuItem value={"tauranga"}> Tauranga </MenuItem>
                                        <MenuItem value={"thamesCoromandel"}> Thames-Coromandel </MenuItem>
                                        <MenuItem value={"timaru"}> Timaru </MenuItem>
                                        <MenuItem value={"upperHutt"}> Upper Hutt </MenuItem>
                                        <MenuItem value={"waihekeIsland"}> Waiheke Island </MenuItem>
                                        <MenuItem value={"waikato"}> Waikato </MenuItem>
                                        <MenuItem value={"waimakariri"}> Waimakariri </MenuItem>
                                        <MenuItem value={"waimate"}> Waimate </MenuItem>
                                        <MenuItem value={"waipa"}> Waipa </MenuItem>
                                        <MenuItem value={"wairoa"}> Wairoa </MenuItem>
                                        <MenuItem value={"waitakereCity"}> Waitakere City </MenuItem>
                                        <MenuItem value={"waitaki"}> Waitaki </MenuItem>
                                        <MenuItem value={"waitomo"}> Waitomo </MenuItem>
                                        <MenuItem value={"wanaka"}> Wanaka </MenuItem>
                                        <MenuItem value={"wellington"}> Wellington </MenuItem>
                                        <MenuItem value={"westernBayOfPlenty"}> Western Bay Of Plenty </MenuItem>
                                        <MenuItem value={"westland"}> Westland </MenuItem>
                                        <MenuItem value={"whakatane"}> Whakatane </MenuItem>
                                        <MenuItem value={"whanganui"}> Whanganui </MenuItem>
                                        <MenuItem value={"whangarei"}> Whangarei </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    required
                                    xs={12}
                                >
                                    <InputLabel>House Size</InputLabel>
                                    <Select
                                        labelId="house-size-outlined-label"
                                        id="house-size-selector"
                                        //value={age}
                                        //onChange={handleChange}
                                        label="House Size"
                                        required
                                        value={houseSize}
                                        onChange={houseSizeChangeHandler}
                                        fullWidth
                                    >
                                        <MenuItem value={1}>1 bedroom</MenuItem>
                                        <MenuItem value={2}>
                                            2 bedrooms
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            3 bedrooms
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            4 bedrooms
                                        </MenuItem>
                                        <MenuItem value={5}>
                                            5+ bedrooms
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="date"
                                    id="moving-date-input"
                                    name="Moving date"
                                    label="Approximate move date"
                                    variant="outlined"
                                    value={moveDate}
                                    onChange={dateInputChangeHandler}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.button}
                                    endIcon={<SendIcon></SendIcon>}
                                >
                                    Get Quotes!
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright/>
            </Container>

            <Modal open={formSubmitted}>
                <Card>
                    <CardContent>
                        {" "}
                        <Typography> Quotes are on the way!</Typography>
                    </CardContent>{" "}
                </Card>
            </Modal>
        </>
    );
};

export default QuoteForm;
