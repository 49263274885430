import React, { useReducer, useEffect } from "react";
import {
    DialogContent,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Hidden,
    Checkbox,
    Switch,
    Typography,
} from "@material-ui/core";

import moverPreferenceService from "../../services/moverPreferencesService";

import RegionSelectReducer from "./RegionSelectReducer";


const styles = (theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
    },

    badge: {
        top: theme.spacing(2),
        right: -theme.spacing(2),
    },

    loadingBadge: {
        top: "50%",
        right: "50%",
    },

    avatar: {
        marginRight: "auto",
        marginLeft: "auto",

        width: theme.spacing(14),
        height: theme.spacing(14),
    },

    nameInitials: {
        cursor: "default",
    },

    personIcon: {
        fontSize: theme.spacing(7),
    },

    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),

        minHeight: "initial",
    },
});

const MoverTab = (userData, classes) => {
    // define the mover service areas that are set on their profile
    const moverServiceAreas = userData.userData.serviceAreas;
    //console.log(moverServiceAreas);

    // Call the reducer and set the initial value with the destructured array of user data
    // initial state = reducer(...moverServiceAreas)
    // on change dispach to reducer
    const [state, dispatch] = useReducer(
        RegionSelectReducer,
        moverServiceAreas
    );

    const nestedAreas = {
        northland: [
            { name: "Far North", id: "farNorth", checked: state.farNorth },
            { name: "Kaipara", id: "kaipara", checked: state.kaipara },
            { name: "Whangarei", id: "whangarei", checked: state.whangarei },
        ],
        auckland: [
            {
                name: "Auckland City",
                id: "aucklandCity",
                checked: state.aucklandCity,
            },
            { name: "Franklin", id: "franklin", checked: state.franklin },
            {
                name: "Hauraki Gulf Islands",
                id: "haurakiGulfIslands",
                checked: state.haurakiGulfIslands,
            },
            {
                name: "Manukau City",
                id: "manukauCity",
                checked: state.manukauCity,
            },
            {
                name: "North Shore City",
                id: "northShoreCity",
                checked: state.northShoreCity,
            },
            { name: "Papakura", id: "papakura", checked: state.papakura },
            { name: "Rodney", id: "rodney", checked: state.rodney },
            {
                name: "Waiheke Island",
                id: "waihekeIsland",
                checked: state.waihekeIsland,
            },
            {
                name: "Waitakere City",
                id: "waitakereCity",
                checked: state.waitakereCity,
            },
        ],
        waikato: [
            {
                name: "Hamilton",
                id: "aucklandCity",
                checked: state.aucklandCity,
            },
            { name: "Hauraki", id: "franklin", checked: state.franklin },
            {
                name: "Matamata-Piako",
                id: "matamataPiako",
                checked: state.matamataPiako,
            },
            { name: "Otorohanga", id: "otorohanga", checked: state.otorohanga },
            {
                name: "South Waikato",
                id: "southWaikato",
                checked: state.southWaikato,
            },
            { name: "Taupo", id: "taupo", checked: state.taupo },
            {
                name: "Thames-Coromandel",
                id: "thamesCoromandel",
                checked: state.thamesCoromandel,
            },
            { name: "Waikato", id: "waikato", checked: state.waikato },
            { name: "Waipa", id: "waipa", checked: state.waipa },
            { name: "Waitomo", id: "waitomo", checked: state.waitomo },
        ],
        bayOfPlenty: [
            { name: "Kawerau", id: "kawerau", checked: state.kawerau },
            { name: "Opotiki", id: "opotiki", checked: state.opotiki },
            { name: "Rotorua", id: "rotorua", checked: state.rotorua },
            { name: "Tauranga", id: "tauranga", checked: state.tauranga },
            {
                name: "Western Bay Of Plenty",
                id: "westernBayOfPlenty",
                checked: state.westernBayOfPlenty,
            },
            { name: "Whakatane", id: "whakatane", checked: state.whakatane },
        ],
        gisborne: [
            { name: "Gisborne", id: "gisborne", checked: state.gisborne },
        ],
        hawkesBay: [
            {
                name: "Central Hawke's Bay",
                id: "centralHawkesBay",
                checked: state.centralHawkesBay,
            },
            { name: "Hastings", id: "hastings", checked: state.hastings },
            { name: "Napier", id: "napier", checked: state.napier },
            { name: "Wairoa", id: "wairoa", checked: state.wairoa },
        ],
        taranaki: [
            {
                name: "New Plymouth",
                id: "newPlymouth",
                checked: state.newPlymouth,
            },
            {
                name: "South Taranaki",
                id: "southTaranaki",
                checked: state.southTaranaki,
            },
            { name: "Stratford", id: "stratford", checked: state.stratford },
        ],
        manawatuWanganui: [
            { name: "Horowhenua", id: "horowhenua", checked: state.horowhenua },
            { name: "Manawatu", id: "manawatu", checked: state.manawatu },
            {
                name: "Palmerston North",
                id: "palmerstonNorth",
                checked: state.palmerstonNorth,
            },
            { name: "Rangitikei", id: "rangitikei", checked: state.rangitikei },
            { name: "Ruapehu", id: "ruapehu", checked: state.ruapehu },
            { name: "Tararua", id: "tararua", checked: state.tararua },
            { name: "Whanganui", id: "whanganui", checked: state.whanganui },
        ],
        wellington: [
            { name: "Carterton", id: "carterton", checked: state.carterton },
            { name: "Kapiti Coast", id: "kapiti", checked: state.kapiti },
            { name: "Lower Hutt", id: "lowerHutt", checked: state.lowerHutt },
            { name: "Masterton", id: "masterton", checked: state.masterton },
            { name: "Porirua", id: "porirua", checked: state.porirua },
            {
                name: "South Wairarapa",
                id: "southWairarapa",
                checked: state.southWairarapa,
            },
            { name: "Upper Hutt", id: "upperHutt", checked: state.upperHutt },
            { name: "Wellington", id: "wellington", checked: state.wellington },
        ],
        nelsonTasman: [
            { name: "Nelson", id: "nelson", checked: state.nelson },
            { name: "Tasman", id: "tasman", checked: state.tasman },
        ],
        marlborough: [
            { name: "Blenheim", id: "blenheim", checked: state.blenheim },
            { name: "Kaikoura", id: "kaikoura", checked: state.kaikoura },
            {
                name: "Marlborough",
                id: "marlborough",
                checked: state.marlborough,
            },
        ],
        westCoast: [
            { name: "Buller", id: "buller", checked: state.buller },
            { name: "Grey", id: "grey", checked: state.grey },
            { name: "Westland", id: "westland", checked: state.westland },
        ],
        canterbury: [
            { name: "Ashburton", id: "ashburton", checked: state.ashburton },
            {
                name: "Banks Peninsula",
                id: "banksPeninsula",
                checked: state.banksPeninsula,
            },
            {
                name: "Christchurch City",
                id: "christchurchCity",
                checked: state.christchurchCity,
            },
            { name: "Hurunui", id: "hurunui", checked: state.hurunui },
            { name: "Mackenzie", id: "mackenzie", checked: state.mackenzie },
            { name: "Selwyn", id: "selwyn", checked: state.selwyn },
            { name: "Timaru", id: "timaru", checked: state.timaru },
            {
                name: "Waimakariri",
                id: "waimakariri",
                checked: state.waimakariri,
            },
            { name: "Waimate", id: "waimate", checked: state.waimate },
        ],
        otago: [
            {
                name: "Central Otago",
                id: "centralOtago",
                checked: state.centralOtago,
            },
            { name: "Clutha", id: "clutha", checked: state.clutha },
            { name: "Dunedin", id: "dunedin", checked: state.dunedin },
            {
                name: "Queenstown-Lakes",
                id: "queenstownLakes",
                checked: state.queenstownLakes,
            },
            {
                name: "South Otago",
                id: "southOtago",
                checked: state.southOtago,
            },
            { name: "Waitaki", id: "waitaki", checked: state.waitaki },
            { name: "Wanaka", id: "wanaka", checked: state.wanaka },
        ],
        southland: [
            { name: "Catlins", id: "catlins", checked: state.catlins },
            { name: "Gore", id: "gore", checked: state.gore },
            {
                name: "Invercargill",
                id: "invercargill",
                checked: state.invercargill,
            },
            { name: "Southland", id: "southland", checked: state.southland },
        ],
    };

    function AreaSelectorGenerator(areas) {
        const areasList = areas.areas.map((area) => {
            return (
                <>
                    <ListItem key={area.id}>
                        <ListItemText primary={area.name} secondary="" />

                        <ListItemSecondaryAction>
                            <Hidden xsDown>
                                <Checkbox
                                    color="primary"
                                    checked={area.checked}
                                    onChange={() => dispatch({ type: `${area.id}` })} />
                            </Hidden>

                            <Hidden smUp>
                                <Switch
                                    color="primary"
                                    checked={area.checked}
                                    onChange={() => dispatch({ type: `${area.id}` })} />
                            </Hidden>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <Box mt={2} mb={1}>
                        <Divider light />
                    </Box>
                </>
            );
        });

        return <List disablePadding>{areasList}</List>;
    }

    // useEffect to update the database when the state changes
    useEffect(() => {
        moverPreferenceService.updateServiceAreas(state);
    }, [state]);

    return (
        // This renders the form
        // top is the open for quotes slider
        // later is the regions sections
        <DialogContent classes={{ root: classes.dialogContent }}>
            <List disablePadding key="service_area_list">
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        North Land
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.northland}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Auckland
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator areas={nestedAreas.auckland} />
                <Box mt={2} mb={1}></Box>

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Waikato
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator  areas={nestedAreas.waikato} />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Bay Of Plenty
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.bayOfPlenty}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Gisborne
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator areas={nestedAreas.gisborne} />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Hawke's bay
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.hawkesBay}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Taranaki
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator areas={nestedAreas.taranaki} />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Manawatu-Wanganui
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.manawatuWanganui}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Wellington
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.wellington}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Nelson-Tasman
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.nelsonTasman}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Marlborough
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.marlborough}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        West Coast
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.westCoast}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Canterbury
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.canterbury}
                />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Otago
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator areas={nestedAreas.otago} />

                <Box mt={2} mb={1}></Box>
                <Box mb={1}>
                    <Typography align={"center"} variant="h5">
                        Southland
                    </Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <Divider light />
                </Box>
                <AreaSelectorGenerator
                    areas={nestedAreas.southland}
                />
                <Hidden style={styles}> {""}</Hidden>

                <Box />
            </List>
        </DialogContent>
    );
};

export default MoverTab;
